
import { instance } from '@/utils/http.js'

// 导出试卷
export const exportWord = function (params) {
  // let params = {
  //   edu_paper_id,
  //   show_source,
  //   detail
  // }
  return instance({
    url: '/api/v1/combination/paper_print_word',
    responseType: "blob",
    method: 'get',
    params
  })
}

// 导出考察细目表
export const exporExcel = function (edu_paper_id) {
  let data = {
    edu_paper_id,
  }
  return instance({
    url: '/api/v1/combination/paper_question_excel',
    responseType: "blob",
    method: 'post',
    data
  })
}


