
<template>
  <!--  试卷详情的导出和收藏按钮 -->
  <div>
    <el-row :gutter="10">
      <el-col :span="24">
        <div class="all_btn_wrap">
          <div class="nowarp">
            <button class="oper_btn btn1"
                    @click="printPaper(0)">
              导出试卷
            </button>
            <div style="width:5px;height:5px"></div>
            <button class="oper_btn btn2"
                    @click="printPaper(1)">
              导出题目及答案解析
            </button>
            <button class="oper_btn btn1"
                    
                    @click="printStatic($route.query.edu_paper_id)">
              导出考查细目表
            </button>
          </div>
          <div class="nowarp">
            <button class="oper_btn btn1"
                    @click="collectPaper">
              <span v-show="isCollect==0">
                收 藏</span>
              <span v-show="isCollect>0">
                取消收藏</span>
            </button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { exporExcel } from '@/api/paper.js'
export default {
  data () {
    return {
      is_examine: 0, //是否显示考查细目表
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    isCollect: {
      type: Number,
      default: 0
    }
  },
  mounted () {
    let user = window.localStorage.getItem("userInfo")
    if (user) {
      this.is_examine = JSON.parse(user).is_examine
    }
  },
  methods: {
    async printStatic (edu_paper_id) {
      const data = await exporExcel(edu_paper_id)
      const a = document.createElement("a")
      a.href = window.URL.createObjectURL(data)
      a.download = `${this.name}考查细目表.xlsx`
      a.click()
    },
    printPaper (num) {
      // window.open(this.$basicUrl + `/api/v1/combination/paper_print?edu_paper_id=${this.$route.query.edu_paper_id}&detail=${num}`, '_blank');
      this.$http({
        url: '/api/v1/combination/paper_print_word',
        method: 'get',
        responseType: "blob",
        params: {
          edu_paper_id: this.$route.query.edu_paper_id,
          detail: num
        }
      }).then(res => {
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(res.data)
        a.download = `${this.name || '教师试卷'}.docx`
        a.click()
      })
    },
    collectPaper () {
      this.$http({
        url: '/api/v1/combination/collect',
        method: 'post',
        data: {
          edu_paper_id: this.$route.query.edu_paper_id
        }
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        this.$parent.getInfo()
      })
    }
  }
}
</script>


<style lang="scss" scoped>
.nowarp {
  display: flex;
  .oper_btn {
    padding: 2px 20px;
    font-size: 16px;
    margin-right: 15px;
    // height: 30px;
    line-height: 30px;
    border-radius: 22px;
    outline: none;
    border: 0;
    color: white;
    display: inline-block;
  }
  .btn1 {
    background: #3f9eff;
    &:hover {
      background: #64b0fb;
    }
  }
  .btn2 {
    background: #fe765f;
    &:hover {
      background: rgb(251, 144, 125);
    }
  }
}
.all_btn_wrap {
  width: calc(100% - 150px);
  display: flex;
  justify-content: space-between;
}
</style>